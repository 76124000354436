p {
    margin: 0;
}
.header__items{
    display: flex;
    align-items: self-start;
    margin: 10px;
    justify-content: space-between;
    box-shadow: 15px 15px 30px rgba(0,0,0, .15)
}
.header__items-text{
    font-size: 30px;
    margin: 0px 30px 0px 0px;
}
.menu-header{
    margin-top: 30px;
    padding: 5px 0px 5px 0px;
    background-color: #3d5a80;
    border-radius: 4px;
    box-shadow: 15px 15px 30px rgba(1, 7, 2, 0);
    color: #fff;
}
.menu-items{
    position: relative;
    display: flex;
    vertical-align: middle;
    justify-content: center;
}
.menu-item{
    display: inline-block;
    margin: 5px;
    font-weight: 700;
    text-decoration: none;
    user-select: none;
    padding: .5em 2em;
    outline: none;
    border: 2px solid;
    border-radius: 1px;
    transition: 0.2s;
}
 .menu-item:hover { background: rgba(121, 252, 95, 0.932); }
 .menu-item:active { background: rgb(165, 26, 26); }
 .main-content{
    margin-top: 30px;
    padding: 5px 0px 5px 0px;
    background-color: #3d5a80;
    border-radius: 40px;
    box-shadow: 15px 15px 30px rgba(1, 7, 2, 0);
    color: #fff;
 }
.vendor-prise-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: 5px solid black;
    margin: 10px 0px 0px 0px;
   }
.vendor-prise{
    flex: 1 1 auto;
    margin: 0 5px;
   }
.vendor-prise-item {
    margin: 0 50px;
}
.vendor-header-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 110px 0px 0px;
}
.h1center {
    text-align: center;
}
.hilde-menu {
    display: none;
}
.rite__info{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border: 1px solid black;
}
.rite__content-info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border: 1px solid black;
}
.rite__content-number{
    width: 15%;
}
.rite__content-fio{
    width: 30%;
}
.rite__content-summa{
    width: 10%;
}
.rite__content-merchant {
    width: 10%;
}
.rite__content-data{
    width: 5%;
}
.conten__info-status{
    width: 15%;
}
.conten__info-fio{
    width: 30%;
}
.conten__info-summa{
    width: 10%;
}
.conten__info-merchant {
    width: 10%;
}
.conten__info-data{
    width: 5%;
}
.conten__info-vepprice{
    width: 100%;
}



.popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, .5);
	transition: all 1.5s ease;
	opacity: 0;
	visibility: hidden;
}

.card-popup {
    background-color: rgba(0, 0, 0, .5);
}
.popup_open {
    
    visibility: visible;
    height: 100%;
    opacity: 1;
    transition-delay: 0s, 0s;
  }
  .popup__card-container {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 530px;
    width: 100%;
    height: 300px;
    margin: auto;
    justify-content: center;
    background-color: #fff;
    text-align: center;
    color: #000000;
}

.popup__conteiner {
    max-width: 430px;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
}




.activ__content{
    height: 100px;
    width: 100px;
    background-color: azure;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}